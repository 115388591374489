.footer-main
 {
    background-color: #000;
    padding: 20px 0 10px 0;
}
.footer-main .footer-logo {
    width: 278px;
}


.footer-main .footer-social-links {
    margin-top: 25px;
    margin-left: 0;
    font-size: 20px;
}
.footer-main .footer-social-links li {
    margin-right: 0px;
	display: inline-block;
    padding-right: 7px;
    padding-left: 7px;
}
.footer-main .footer-social-links li a i{
    color:#ffffff;
	font-size:20px;
}




.footer-navigation h5 {
    
    margin-bottom: 15px;
	color:white;
	text-decoration: none;
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	margin-top:15px;

}
.footer-navigation h5 a{
color:#fff;
}
.footer-navigation a  {
    
	text-decoration: none;display: block;

}
.footer-navigation a:hover {
    
	text-decoration: underline;
	color:#ffffff;

}

.btn.footer-btn-white {
	margin-top: 20px;
	border-radius: 0;
	font-size: 13px;
	border-radius: 4px;
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #000;
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: none;
	border-color: #fff;
	border-style: none;
	border-width: 1px;
	padding: 12px 20px;
	font-family: Montserrat, sans-serif;
}

.btn.footer-btn-white:hover {
	background-color: #fff;
	/* -webkit-transform: translate(0px, -5px);
	-ms-transform: translate(0px, -5px);
	transform: translate(0px, -5px); */
	color: #000;
	text-decoration: none;
}


.footer-main .footer-p
{
	color:white;
	font-size:15px;
	font-weight:normal;
	margin-bottom:20px;
	margin-top:10px;
}
.menu-section-break {
    border: 1px solid #f5f5f585;
    width: 95%;
	margin-bottom: 20px;
}
.copyright-text
{
	font-size:14px;
	color:#ffffff;
	margin-top:10px;
	margin-bottom:10px;
	font-weight: normal;
}
.copyright-text a
{
	color:#ffffff;
}
.copyright-text a:hover
{
	text-decoration: underline;color:#ffffff;
}

@media only screen and (max-width: 767px) {
	.footer-main {
		padding: 20px;
	}
	.footer-main .footer-logo {
		width: 200px;
	}
	.footer-child
	{
		text-align:center;
	}
	#footer-navigation {
        text-align: left;
        margin-left: 0px;
		padding-left:30px;
        margin-top: 35px;
    }
	.copyright-text {
		font-size: 12px;
	}
	.footer-navigation h5 {
		margin-bottom: 0;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
	.footer-main {
		padding: 20px;
	}
	.footer-main .footer-logo {
		width: 200px;
	}
	.footer-child
	{
		text-align:center;
	}
	#footer-navigation {
        text-align: left;
        margin-left: 0px;
		padding-left:30px;
        margin-top: 35px;
    }
	.copyright-text {
		font-size: 12px;
	}
	.footer-navigation h5 {
		margin-bottom: 0;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	
}


@media (max-width: 1024px) and (min-width: 768px) {
	.footer-main {
		padding: 20px;
	}
	.footer-main .footer-logo {
		width: 200px;
	}
	.footer-child
	{
		text-align:center;
	}
	#footer-navigation {
        text-align: left;
        margin-left: 0px;
		padding-left:30px;
        margin-top: 35px;
    }
	.copyright-text {
		font-size: 12px;
	}
	.footer-navigation h5 {
		margin-bottom: 0;
		margin-top: 0px;
		margin-bottom: 10px;
	}
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

	.footer-child
	{
		text-align:left;
	}
	#footer-navigation {
        margin-left: 0;
		padding-left:0;
        margin-top: 0px;
    }
	.footer-navigation h5 {
		margin-bottom: 15px;
		margin-top: 15px;
		margin-bottom: 0;
	}
}