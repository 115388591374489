.hotel-brand-container
{
  /* min-height: 100vh; */
  /* padding-left:0;
  padding-right:0; */
}
.hotel-search-brand-container
{
  /* min-height: 100vh; */
  /* padding-left:0;
  padding-right:0; */
  padding-top:50px;
  padding-bottom: 50px;
}
.hotel-brand-container-row
{
  position: relative;
}
.hotel-brand-container .brand-hero-image {
  width: 100%;
  min-height: 220px;
  max-height: 340px;
  padding-left:0;
  padding-right:0;
}
.hotel-brand-container .brand-banner-text {
    
    background: rgba(0, 0, 0, 0.5);
    padding-top: 10%;

    position: absolute;
    color: #FFF;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.hotel-brand-container .brand-banner-text .banner-brand-logo {
  max-width: 250px;
  float: left;
  margin-right:20px;
}
.hotel-brand-container .brand-banner-text .banner-search-container {
  display: block;
  overflow: hidden;
  padding-left: 40px;
  margin-top:25px;
}
.hotel-brand-container .brand-banner-text .search-icon-brand
{
  margin-right: 5px;
  float: left;
  margin-top:10px;
  display: inline-block;
}
.hotel-brand-container .brand-banner-text  .brand-span
{
overflow: hidden;
display: block;
}
.hotel-brand-container .brand-banner-text .hotel-search-input
{
  color: #FFF;
  border:none;
  border-bottom: 1px solid #fff;
  background: transparent;
  border-radius: 0;
  -webkit-box-shadow: none;
    width: 100%;
    height: 34px;
    padding: 6px 0px;
    font-size: 14px;
    line-height: 1.42857143;
}
.hotel-brand-container .brand-banner-text .hotel-search-input:focus
{
  border-bottom: 1px solid #8f8888;
}
.hotel-brand-container .brand-banner-text .hotel-search-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

.hotel-brand-container .brand-banner-text .hotel-search-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

.hotel-brand-container .brand-banner-text .hotel-search-input::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}


.brand-search-title
{
  font-size: 24px;
  color:#000;
  margin-top:10px;
  margin-bottom:20px;
  text-transform: capitalize;
  font-weight: 600;
}


@media only screen and (max-width: 767px) 
{
 
  .hotel-search-brand-container {
    padding-top: 30px;
    padding-bottom: 30px;
}
  .hotel-brand-container .brand-banner-text {
    padding-top: 0;
    padding-bottom: 15px;
    text-align:center;
}
.hotel-brand-container .brand-banner-text .banner-brand-logo {
  max-width: 80%;
  max-height: 100px;
  float: none;
  margin-right: 0;
}
.hotel-brand-container .brand-banner-text .banner-search-container {
 
  padding-left: 0;
  margin-top: 25px;
}
.hotel-brand-container .brand-banner-text .hotel-search-input
{
  font-size: 12px;
}
.brand-search-title {
  font-size: 18px;
}



}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) 
{

  .hotel-search-brand-container {
    padding-top: 30px;
    padding-bottom: 30px;
}
  .hotel-brand-container .brand-banner-text {
    padding-top: 0;
    padding-bottom: 15px;
    text-align:center;
}
.hotel-brand-container .brand-banner-text .banner-brand-logo {
  max-width: 80%;
  max-height: 100px;
  float: none;
  margin-right: 0;
}
.hotel-brand-container .brand-banner-text .banner-search-container {
 
  padding-left: 0;
  margin-top: 25px;
}
.hotel-brand-container .brand-banner-text .hotel-search-input
{
  font-size: 12px;
}
.brand-search-title {
  font-size: 18px;
}
  

}

@media (max-width: 1024px) and (min-width: 768px) 
{
  
  .hotel-search-brand-container {
    padding-top: 50px;
    padding-bottom: 50px;
}
  .hotel-brand-container .brand-banner-text {
    padding-top: 0;
    padding-bottom: 15px;
    text-align:center;
}
.hotel-brand-container .brand-banner-text .banner-brand-logo {
  max-width: 80%;
  max-height: 100px;
  float: none;
  margin-right: 0;
}
.hotel-brand-container .brand-banner-text .banner-search-container {
 
  padding-left: 0;
  margin-top: 25px;
}
.hotel-brand-container .brand-banner-text .hotel-search-input
{
  font-size: 14px;
}

.brand-search-title {
  font-size: 18px;
}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) 
{

  .hotel-search-brand-container {
    padding-top: 50px;
    padding-bottom: 50px;
}
  .hotel-brand-container .brand-banner-text {
    padding-top: 10%;
    padding-bottom: 15px;
    text-align:inherit;
}
.hotel-brand-container .brand-banner-text .banner-brand-logo {
  max-width: 250px;
  max-height: auto;
  float: left;
  margin-right: 20px;
}
.hotel-brand-container .brand-banner-text .banner-search-container {
 
  padding-left: 0;
  margin-top: 25px;
}
.hotel-brand-container .brand-banner-text .hotel-search-input
{
  font-size: 12px;
}
.brand-search-title {
  font-size: 24px;
}
}
