


.common-pages-body p
{
	text-align:justify;
	font-size: 14px;
}
.common-pages-body h3
{
	font-size: 18px;
	font-weight: 400;
	text-transform: capitalize;
	text-align: left;
}
@media only screen and (max-width: 767px) {
	
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {

	
}


@media (max-width: 1024px) and (min-width: 768px) {

	
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

	
	
}