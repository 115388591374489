

.enquiry-page-container 
{
  min-height: 100vh;
}
.enquiry-page-container  .enquiry-page-row
{
  position: relative;
  height: 800px;
  padding: 0px;
  background-color: transparent;
  background-position: 0px 0px, 50% 40%;
  background-size: auto, cover;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  background-image: radial-gradient(circle farthest-corner at 50% 50%, hsla(0, 0%, 100%, 0.6) 58%, transparent), url("../../../public/assets/img/Enquiry/enquiry-page-row-bg.jpg");
}
.flex-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.enquiry-page-container  .enquiry-page-row .inquire-form-container
{
  width: 800px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-top:50px;
  margin-left: auto;
  padding: 40px;
  background-color: #fff;
}

.enquiry-page-container  .enquiry-page-row .inquire-form-container .inquire-title
{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #030303;
  font-size: 15px;
  line-height: 21px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
}

.enquiry-page-container  .enquiry-page-row .inquire-form-container .inquire-heading {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  text-align: center;
}
.enquiry-page-container  .enquiry-page-row .inquire-form-container .form-label {
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
}
.enquiry-page-container  .enquiry-page-row .inquire-form-container .form-field {
  height: 50px;
  padding: 0px 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #cacaca;
  border-radius: 6px;
}
.enquiry-page-container  .enquiry-page-row .inquire-form-container .form-field:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}
.enquiry-page-container  .enquiry-page-row .inquire-form-container  .btn-referal-link-a
{
  padding: 0;
  text-decoration: underline;
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
  font-size: 14px;
}
.enquiry-page-container  .enquiry-page-row .inquire-form-container  .btn-membership-preview
{
  margin-right: 10px;
  margin-left: 0px;
  padding: 15px 40px;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  border-radius: 5px;
  background-color: #000;
  background-image: linear-gradient(240deg, #000, #505050);
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  color: white;
    line-height: inherit;
    text-decoration: none ;
}
.enquiry-page-container  .enquiry-page-row .inquire-form-container  .btn-membership-preview:hover {
  background-color: #000;
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #fff;
}







.enquiry-faq-container
 {
 padding:30px 55px;
}


.enquiry-faq-container .faq-heading {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
}
.enquiry-faq-container .question-div
{
  margin-top: 20px;
}
.enquiry-faq-container .question-div h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
.enquiry-faq-container  .faq-details {
  font-size: 12px;
  line-height: 18px;
}

.enquiry-modal .modal-bg-image
{width: 100%;
  height: 564px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-image: url("../../../public/assets/img/benifits/allbenifits-img.png"); */
  background-image: url("../../../public/assets/img/Enquiry/inquiry-modal-bg.png");
 
}
.enquiry-modal .btn-close{
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  opacity: 1;
}
.enquiry-modal h2 {
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
  color:#ffffff;
}
.enquiry-modal .forbes-logo {
  width: 96px;
  padding-bottom: 9px;
}
.enquiry-modal h1 {
  color: #DDF3FF;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
}

.enquiry-modal .modal-preview-button
{
  border-radius: 3px;
  background-color: #208CC9;
  border: none;
  width: 280px;
  height: 54px;
  color: #FFF !important;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}









@media only screen and (max-width: 767px) 
{
  .enquiry-page-container .enquiry-page-row .inquire-form-container 
  {
    width: 100%;
    padding: 20px;
}


.enquiry-page-container .enquiry-page-row
{
  height: auto;
        padding-right: 10px;
        padding-left: 10px;
        background-position: 0px 0px, 50% 0%;
        padding-top: 70px;
        padding-bottom: 70px;
}


.enquiry-page-container .enquiry-page-row .inquire-form-container .inquire-heading {
  font-size: 20px;
}

.enquiry-page-container .enquiry-page-row .inquire-form-container .form-label {
  font-size: 10px;
}
.enquiry-page-container .enquiry-page-row .inquire-form-container .form-field {
  height: 40px;
}
.enquiry-page-container .enquiry-page-row .btn-center-mobile
{
  text-align:center;
}
.enquiry-page-container .enquiry-page-row .inquire-form-container .btn-membership-preview {
  margin-right: 0;
}
.enquiry-faq-container .faq-heading {
  font-size: 21px;
}
.enquiry-faq-container .question-div h6 {
  font-size: 14px;
}
.enquiry-faq-container .faq-details {
  font-size: 12px;
}
.enquiry-page-container {
  min-height: auto;
}

.enquiry-modal h2 {
  font-size: 20px;
}

.enquiry-modal h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.enquiry-modal .modal-preview-button {
  width: 250px;
  height: 54px;
  font-size: 14px;
}
.enquiry-modal .forbes-logo {
  width: 70px;
  padding-bottom: 10px;
}


}



@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
 
  .enquiry-page-container .enquiry-page-row .inquire-form-container 
  {
    width: 100%;
    padding: 20px;
}


.enquiry-page-container .enquiry-page-row
{
  height: auto;
        padding-right: 10px;
        padding-left: 10px;
        background-position: 0px 0px, 50% 0%;
        padding-top: 70px;
        padding-bottom: 70px;
}


.enquiry-page-container .enquiry-page-row .inquire-form-container .inquire-heading {
  font-size: 20px;
}

.enquiry-page-container .enquiry-page-row .inquire-form-container .form-label {
  font-size: 10px;
}
.enquiry-page-container .enquiry-page-row .inquire-form-container .form-field {
  height: 40px;
}
.enquiry-page-container .enquiry-page-row .btn-center-mobile
{
  text-align:center;
}
.enquiry-page-container .enquiry-page-row .inquire-form-container .btn-membership-preview {
  margin-right: 0;
}
.enquiry-faq-container .faq-heading {
  font-size: 21px;
}
.enquiry-faq-container .question-div h6 {
  font-size: 14px;
}
.enquiry-faq-container .faq-details {
  font-size: 12px;
}
.enquiry-page-container {
  min-height: auto;
}

.enquiry-modal h2 {
  font-size: 20px;
}

.enquiry-modal h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.enquiry-modal .modal-preview-button {
  width: 250px;
  height: 54px;
  font-size: 14px;
}
.enquiry-modal .forbes-logo {
  width: 70px;
  padding-bottom: 10px;
}








}


@media (max-width: 1024px) and (min-width: 768px) 
{
  .enquiry-page-container .enquiry-page-row .inquire-form-container 
  {
    width: 100%;
    padding: 20px;
}


.enquiry-page-container .enquiry-page-row
{
  height: auto;
        padding-right: 10px;
        padding-left: 10px;
        background-position: 0px 0px, 50% 0%;
        padding-top: 70px;
        padding-bottom: 70px;
}


.enquiry-page-container .enquiry-page-row .inquire-form-container .inquire-heading {
  font-size: 20px;
}

.enquiry-page-container .enquiry-page-row .inquire-form-container .form-label {
  font-size: 10px;
}
.enquiry-page-container .enquiry-page-row .inquire-form-container .form-field {
  height: 40px;
}
.enquiry-page-container .enquiry-page-row .btn-center-mobile
{
  text-align:center;
}
.enquiry-page-container .enquiry-page-row .inquire-form-container .btn-membership-preview {
  margin-right: 0;
}
.enquiry-faq-container .faq-heading {
  font-size: 21px;
}
.enquiry-faq-container .question-div h6 {
  font-size: 14px;
}
.enquiry-faq-container .faq-details {
  font-size: 12px;
}
.enquiry-page-container {
  min-height: auto;
}


.enquiry-modal h2 {
  font-size: 20px;
}

.enquiry-modal h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.enquiry-modal .modal-preview-button {
  width: 250px;
  height: 54px;
  font-size: 14px;
}
.enquiry-modal .forbes-logo {
  width: 70px;
  padding-bottom: 10px;
}






}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

  .enquiry-page-container .enquiry-page-row .inquire-form-container 
  {
    width: 800px;
    padding: 20px;
}


.enquiry-page-container .enquiry-page-row
{
  height: 800px;
        padding-right: 0px;
        padding-left: 0px;
        background-position: 0px 0px, 50% 0%;
        padding-top: 70px;
        padding-bottom: 70px;
}


.enquiry-page-container .enquiry-page-row .inquire-form-container .inquire-heading {
  font-size: 24px;
}

.enquiry-page-container .enquiry-page-row .inquire-form-container .form-label {
  font-size: 12px;
}
.enquiry-page-container .enquiry-page-row .inquire-form-container .form-field {
  height: 50px;
}
.enquiry-page-container .enquiry-page-row .btn-center-mobile
{
  text-align:left;
}
.enquiry-page-container .enquiry-page-row .inquire-form-container .btn-membership-preview {
  margin-right: 0;
}

.enquiry-faq-container .faq-heading {
  font-size: 28px;
}
.enquiry-faq-container .question-div h6 {
  font-size: 14px;
}
.enquiry-faq-container .faq-details {
  font-size: 12px;
}
.enquiry-page-container {
  min-height: 100vh;
}





.enquiry-modal h2 {
  font-size: 20px;
}

.enquiry-modal h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.enquiry-modal .modal-preview-button {
  width: 250px;
  height: 54px;
  font-size: 14px;
}
.enquiry-modal .forbes-logo {
  width: 70px;
  padding-bottom: 10px;
}



}




