
.hotels-partner-container
{
  background-color: #fff;
}

.hotels-partner-container .hotels-partner-row .hotels-partner-child
{
  margin-bottom:15px;
}
.hotels-partner-container .hotels-partner-row .hotels-partner-child .premier-partner-logo
{
  width: 100%;
}





@media only screen and (max-width: 767px) 
{
 





}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) 
{
  

  

}

@media (max-width: 1024px) and (min-width: 768px) 
{
  
 
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) 
{
 
}
