/* body {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Montserrat", sans-serif !important;
}
 */


.login-footer {
  background-color: #000;
  text-align: center;
  padding: 45px 0;
  color: #ffffff;
}
.login-footer p {
  color: #ffffff;
  font-size: 15px;
  margin-bottom: 10px;
}
.login-footer sub {
  font-size: 14px;
}
.login-footer .customsection-break {
  width: 65px;
  border-bottom: 2px solid #ffffff;
  margin: 20px auto;
  opacity: 0.7;
}
.login-footer .login-footer-ul li {
  padding: 0 10px;
  display: inline-block;
}
.login-footer .login-footer-ul li a {
  font-size: 16px;
  font-weight: 400;
}

.login-footer .login-footer-social-link {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 0;
}
.login-footer .login-footer-social-link > li {
  padding: 0;
  display: inline-block;
}
.login-footer .login-footer-social-link > li .btn-circle {
  height: 39px;
  width: 39px;
  margin: 0 10px;
  border-radius: 50%;
  /* line-height: 39px; */
  color: #fff;
}
.bg-blue {
  background-color: #2a74e5;
}
.login-footer .copyright-text {
  font-size: 12px;
}
@media only screen and (max-width: 767px) {
  .login-footer p {
    font-size: 18px;
  }
  .login-footer sub {
    font-size: 13px;
  }
  .login-footer .login-footer-ul li a {
    font-size: 14px;
  }

  .login-footer .copyright-text {
    font-size: 9px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
  .login-footer p {
    font-size: 18px;
  }
  .login-footer sub {
    font-size: 13px;
  }
  .login-footer .login-footer-ul li a {
    font-size: 14px;
  }

  .login-footer .copyright-text {
    font-size: 9px;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .login-footer p {
    font-size: 18px;
  }
  .login-footer sub {
    font-size: 13px;
  }
  .login-footer .login-footer-ul li a {
    font-size: 14px;
  }

  .login-footer .copyright-text {
    font-size: 9px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .login-footer p {
    font-size: 18px;
  }
  .login-footer sub {
    font-size: 13px;
  }
  .login-footer .login-footer-ul li a {
    font-size: 14px;
  }

  .login-footer .copyright-text {
    font-size: 9px;
  }
}
