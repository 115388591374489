
.need-help-container 
{
  margin-top: 30px;
  margin-bottom: 30px;
}

.need-help-container .help-topic-heading
{
  font-size: 24px;
  font-weight: 600;
  margin-top:10px;
  margin-bottom: 10px;
  color:#000;
  text-transform: capitalize;
}
.need-help-container .help-topic-anchor
{
  font-size: 14px;
  font-weight: 400;
  color:#000;
  text-transform: inherit;
  text-decoration: underline;
}
.need-help-container .help-topic-anchor:hover
{
  text-decoration: underline;
}

.need-help-container .help-topic-anchor span
{
  text-transform: capitalize;
}
.need-help-container p
{
  font-size: 14px;
}
form.need-help-textbox label
{
  color:#000;
  font-weight: 700;
  font-size: 14px;
}
form.need-help-textbox .form-control
{
  border:1px solid black;
  border-radius: 0;
}
form.need-help-textbox .form-control:focus
{
  border:2px solid black;
  box-shadow: none;
}

form.need-help-textbox textarea
{
  height:150px;
}

form.need-help-textbox .btn.btn-submit-help
{
    font-size: 15px;
    width: auto;;
    margin-bottom: 10px;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-weight: 700;
    border-radius: 0;
    background-color: #037DC2;
}





@media only screen and (max-width: 767px) {


  








}


