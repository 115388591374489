/* body {
	font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: 'Montserrat', sans-serif !important;
}
 */


.btn-color {
	font-size: 100px;
}
.homepage-hero-container
{
	overflow: hidden;
    position: relative;
    flex-direction: column;
    padding: 100px 0;
    height: 450px;

	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.video-overlay {
	/* position: relative;
	margin: 0 0;

	display: block;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0;
	padding-bottom: 0;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center; */

	background-color: rgba(0, 0, 0, 0.15);
	position: absolute;
    height: 100%;
    width: 100%;
    z-index: 3;
}

/* .video-overlay:before {
	content: '';
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 0px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
} */

.bg-vid {
	/* background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%; */
	/* min-width: 100%;
	min-height: 324px;
	height:auto;
	margin: auto; */


	position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
}



.video-details {

	/* align-self: center;
	width: 800px;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin-right: auto;
	margin-left: auto; */

	margin-top: -50px;
    text-align: center;
    z-index: 5;
    max-width: 750px;
    color: #fff;
    padding: 40px;
    position: relative;
}
.video-details.video-details-pt
{
	top: 0px;
}
.video-details h3.homevideo-toptitle {
	margin-top: 10px;
	margin-bottom: 15px;
	color: #fff;
	font-size: 14px;
	line-height:normal;
	font-weight: 700;
	text-transform: none;
}
.video-details h1.homevideo-title {
	margin-top: 10px;
	margin-bottom: 15px;
	color: #fff;
	font-size: 28px;
	line-height:normal;
	font-weight: 600;
	text-transform: none;
}

.video-details p.homevideo-subtitle {
	margin-bottom: 20px;
	margin-top:10px;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	color: #F7F7F7;
	line-height: 25px;
	font-weight:500;
}

.video-details img.homelogo-sett {

	margin-right: auto;
	margin-left: auto;
}

.video-details .video-details-logo {
	margin-top: 30px;
}

.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo1-size {
	width: 130px;
	height: 40px;
}

.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo2-size {
	width: 80px;
	height: 40px;
}

.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo3-size {
	width: 130px;
	height: 40px;
}

.video-details .video-details-logo .video-details-logo-child p {
	margin-top: 20px;
	margin-left: 0;
	color: #fff;
	font-size: 15px;
	line-height: 21px;
	font-weight: 400;
}
.btn.custombutton-transparent {
	margin-top: 20px;
	border-radius: 5px;
	font-size: 12px;
	background-color: transparent;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none;
	border-color: #fff;
	border-style: solid;
	border-width: 2px;
	padding: 10px 35px;
	font-family: Montserrat, sans-serif;
}

.btn.custombutton-transparent:hover {
	background-color: transparent;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
	text-decoration: none;
}
.btn.custombutton-transparent a
{
  color:#fff;
}



/* @media (max-width: 767px) */
@media only screen and (max-width: 767px) {

	.video-details h3.homevideo-toptitle {
		font-size: 15px;
	}

	.video-details {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}

	.video-details h1.homevideo-title {
		font-size: 21px;
		line-height: 34px;
	}

	.video-details p.homevideo-subtitle {
		font-size: 14px;
		line-height: 21px;
	}

	.video-details .video-details-logo {
		margin-top: 40px;
	}

	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo1-size,
	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo3-size {
		width: 70px;
		height: auto;
	}

	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo2-size {
		width: 50px;
		height: auto;
	}

	.video-details .video-details-logo .video-details-logo-child p {
		font-size: 12px;
	}
	.btn.custombutton-transparent {
		font-size: 12px;
	}

	/* .video-overlay.video-overlay-height,
	.bg-vid.video-height
	{
		min-height: 324px;
		height:324px;
	} */
	.video-details.video-details-pt {
		top: 0;
	}


	.homepage-hero-container {
		
		padding: 10px;
		min-height: 324px;
	}













}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {


	.video-details h3.homevideo-toptitle {
        font-size: 15px;
    }
	.video-details {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}

	.video-details h1.homevideo-title {
		font-size: 21px;
		line-height: 30px;
	}

	.video-details p.homevideo-subtitle {
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 5px;
	}

	.video-details .video-details-logo {
		margin-top: 15px;
	}

	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo1-size,
	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo3-size {
		width: 70px;
		height: auto;
	}

	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo2-size {
		width: 50px;
		height: auto;
	}

	.video-details .video-details-logo .video-details-logo-child p {
		font-size: 12px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.btn.custombutton-transparent {
        font-size: 12px;
    }
	/* .video-overlay.video-overlay-height,
	.bg-vid.video-height
	{
		min-height: 324px;
		height:324px;
	} */
	.video-details.video-details-pt {
		top: 0;
	}
	
}


@media (max-width: 1024px) and (min-width: 768px) {

	.video-details h3.homevideo-toptitle {
		font-size: 15px;
	}
	.video-details {
		width: 100%;
		padding-left: 25px;
		padding-right: 25px;
	}

	.video-details h1.homevideo-title {
		font-size: 21px;
		line-height: normal;
	}

	.video-details p.homevideo-subtitle {
		font-size: 14px;
		line-height: normal;
		margin-bottom: 20px;
	}

	.video-details .video-details-logo {
		margin-top: 100px;
	}

	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo1-size,
	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo3-size {
		width: 130px;
		height: 40px;
	}

	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo2-size {
		width: 80px;
		height: 40px;
	}

	.video-details .video-details-logo .video-details-logo-child p {
		font-size: 12px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.btn.custombutton-transparent {
        font-size: 12px;
    }

	/* .video-overlay.video-overlay-height,
	.bg-vid.video-height
	{
		min-height: 324px;
		height:324px;
	} */
	.homepage-hero-container {
        padding: 10px 0;
        height: auto;
        min-height: 324px;
    }
	.video-details.video-details-pt {
		top: 0;
	}
	
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

	.video-details h3.homevideo-toptitle {
		font-size: 15px;
	}
	.video-details {
		width: 100%;
		padding-left: 25px;
		padding-right: 25px;
	}

	.video-details h1.homevideo-title {
		font-size: 40px;
		line-height: normal;
	}

	.video-details p.homevideo-subtitle {
		font-size: 18px;
		line-height: normal;
		margin-bottom: 20px;
	}

	.video-details .video-details-logo {
		margin-top: 100px;
	}

	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo1-size,
	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo3-size {
		width: 130px;
		height: 40px;
	}

	.video-details .video-details-logo .video-details-logo-child img.homelogo-sett.logo2-size {
		width: 80px;
		height: 40px;
	}

	.video-details .video-details-logo .video-details-logo-child p {
		font-size: 12px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.btn.custombutton-transparent {
        font-size: 12px;
    }
	/* .video-overlay.video-overlay-height,
	.bg-vid.video-height
	{
		min-height: 324px;
		height:324px;
	} */
	.video-details.video-details-pt {
		top: 0;
	}

	.homepage-hero-container {
        padding: 100px 0;
        height: auto;
        min-height: 420px;
    }

}