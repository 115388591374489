.submenu-bedges-container
{
	margin:30px 0;
	text-align:center;
}
.submenu-bedges-container .submenu-bedges-child
{
	background-color: #fafafa;
    border: 1px solid #c4c4c4;
    border-radius: 18px;
    text-align: center;
    height: 35px;
    padding: 7px 20px;
	color:#000;
    font-weight: 500;
    margin: 0 5px;
	white-space: nowrap;
	font-size:14px;
}

.submenu-bedges-container .submenu-bedges-child.span-bold
{
	
    font-weight: 700;
}
/* @media (max-width: 767px) */
@media only screen and (max-width: 767px) {
    .submenu-bedges-container .submenu-bedges-child 
    {
        padding: 5px 7px;
        margin: 0 3px;
        font-size: 10px;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
	
    .submenu-bedges-container .submenu-bedges-child 
    {
        padding: 7px 10px;
        margin: 0 5px;
        font-size: 12px;
    }
	


}
@media (max-width: 1024px) and (min-width: 768px) {
	
    .submenu-bedges-container .submenu-bedges-child 
    {
        padding: 7px 10px;
        margin: 0 5px;
        font-size: 12px;
    }

}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

    .submenu-bedges-container .submenu-bedges-child 
    {
        padding: 7px 20px;
        margin: 0 5px;
        font-size: 14px;
    }
}