
.menusearch-container
{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.98);
    z-index: 1000;
}
.menusearch-container .search-close-icon {
  position: absolute;
  top: 42px;
  right: 42px;
  z-index: 1001;
  text-decoration: none;
}
.menusearch-container .search-close-icon:hover {
  text-decoration: underline;
}
.menusearch-container .search-close-icon h4{
  font-size:14px;
  text-transform: uppercase;
}

.menusearch-container .menusearch-container-child{
  margin-top:150px;
}



.menusearch-form {
  display: block;
  margin: 0 auto;
  margin-bottom:60px;
}

.menusearch-form input.menusearch-form-input {
    margin: 0 auto;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 24px;
    border-bottom: 1px solid black !important;
    outline: none;
    border: none;
    color: #555;
    line-height: 1.42857143;
    position:relative;
}
.menusearch-form input.menusearch-form-input:focus {
  border-bottom: 1px solid black !important;
  outline: none;
  border: none;
}
/* .menusearch-form input.menusearch-form-input:focus::-webkit-input-placeholder,
.menusearch-form input.menusearch-form-input:focus::-moz-placeholder,
.menusearch-form input.menusearch-form-input:focus:-ms-placeholder {
  color:red;
} */


.menusearch-close-icon{
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  right: 10px;
  position: absolute;
  top: 17px;
}
.menusearch-close-icon:after {
  content: "X";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: #909090;
  z-index: 1;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 12px;
  box-shadow: 0 0 2px #909090;
  cursor: pointer;
}


.menusearch-form-input:not(:valid) ~ .menusearch-close-icon {
	display: none;
}





.menusearch-form .menusearch-search-icon {
  width: 28px;
  height: 28px;
  margin-right: 15px;
}

.menusearch-container .search-history .search-history-container
{
  margin-bottom:60px;
}
.menusearch-container .search-history .search-history-container h5
{
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color:#000;
  margin-bottom: 10px;
}
.menusearch-container .search-history .search-history-container p.search-history-p
{
  margin-bottom:0;
}
.menusearch-container .search-history .search-history-term
{
  font-size: 14px;
  font-weight: normal;
  text-transform: inherit;
  color:#333;
  margin-bottom: 10px;
}
.menusearch-container .search-history .search-history-term:hover
{
  text-decoration: underline;
}











@media only screen and (max-width: 767px) {
  .menusearch-container .menusearch-container-child {
    margin-top: 70px;
}
.menusearch-form input.menusearch-form-input {
  font-size: 14px;
}
.menusearch-form .menusearch-search-icon {
  display: none;
}
.menusearch-container .search-close-icon {
  top: 10px;
  right: 10px;
}
.menusearch-container .search-close-icon img
{
  width: 20px;
}
.menusearch-container .search-history .search-history-container h5 {
  font-size: 14px;
}
.menusearch-container .search-history .search-history-term {
  font-size: 14px;
}
.menusearch-container .search-history .search-history-container {
  margin-bottom: 30px;
}


}


@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
  .menusearch-container .menusearch-container-child {
    margin-top: 70px;
}
.menusearch-form input.menusearch-form-input {
  font-size: 14px;
}
.menusearch-form .menusearch-search-icon {
  display: none;
}
.menusearch-container .search-close-icon {
  top: 10px;
  right: 10px;
}
.menusearch-container .search-close-icon img
{
  width: 20px;
}
.menusearch-container .search-history .search-history-container h5 {
  font-size: 14px;
}
.menusearch-container .search-history .search-history-term {
  font-size: 14px;
}
.menusearch-container .search-history .search-history-container {
  margin-bottom: 30px;
}

}


@media (max-width: 1024px) and (min-width: 768px) {
  
  .menusearch-container .menusearch-container-child {
    margin-top: 70px;
}
.menusearch-form input.menusearch-form-input {
  font-size: 14px;
}
.menusearch-form .menusearch-search-icon {
  display: block;
}
.menusearch-container .search-close-icon {
  top: 10px;
  right: 10px;
}
.menusearch-container .search-close-icon img
{
  width: 20px;
}
.menusearch-container .search-history .search-history-container h5 {
  font-size: 14px;
}
.menusearch-container .search-history .search-history-term {
  font-size: 14px;
}
.menusearch-container .search-history .search-history-container {
  margin-bottom: 30px;
}
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {


  .menusearch-container .menusearch-container-child {
    margin-top: 150px;
}
.menusearch-form input.menusearch-form-input {
  font-size: 14px;
}
.menusearch-form .menusearch-search-icon {
  display: block;
}
.menusearch-container .search-close-icon {
  top: 42px;
  right: 42px;
}
.menusearch-container .search-close-icon img
{
  width: 20px;
}
.menusearch-container .search-history .search-history-container h5 {
  font-size: 14px;
}
.menusearch-container .search-history .search-history-term {
  font-size: 14px;
}
.menusearch-container .search-history .search-history-container {
  margin-bottom: 60px;
}



}




