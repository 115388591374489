

.filter-select-box
{
    margin-bottom:30px;
    padding-left:15px;
    padding-right:15px;
}
.filter-select-box .select-box-ui
{
    position: relative;
}
.filter-select-box  .select-category-option-mobile
{
    background-color: #fff;
    position:relative;
    padding: 6px 12px;
    border-radius: 0;
    font-size: 14px;
    width: 100%;
    margin-bottom: 15px;
    height: 34px;
    border: 1px solid #000;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.filter-select-box  .select-category-option-mobile:focus
{
    box-shadow:none;
}

.filter-select-box .select-box-icon
{
    position: absolute;
    top: 5px;
    right: 20px;
}

.browse-category-result .filter-by-brands-mobile
{
    padding-left: 25px;
    padding-bottom: 30px;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #fff;
    position: fixed;
    top: 0;
    overflow-y: auto;
    display:none;
}
.browse-category-result .filter-by-brands-mobile.showbrand-box
{
    display:block;
}



.browse-category-result .filter-by-brands-mobile .filter-close {
    position: absolute;
    top: 15px;
    right: 40px;
}
.browse-category-result  .brand-name-mobile
{
font-size: 18px;
font-weight: 6000;
text-transform: capitalize;
}
.browse-category-result .benefit-brand-div-mobile
{
    font-size:14px;
}
.browse-category-result .brands-block-mb
{
    margin-bottom:30px;
}
.browse-category-result .filter-by-brands-mobile .filter-checkbox-mobile {
    display: inline-block;
    height: 12px;
    width: 12px;
    border: 1px solid #888;
    margin-right: 10px;
    position: relative;
    top: 2px;
}
.browse-category-result .benefit-brand-div-mobile.active .filter-checkbox-mobile {
    background-color: #333;
}

.browse-category-result .filter-by-brands-mobile .filter-apply-mobile
{
    background-color: #686868;
    color: #FFF;
    padding: 15px 40px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 0;
    border: none;
    text-decoration: none;
    margin-top:30px;
}






@media only screen and (max-width: 767px) 
{
  



}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) 
{
 

  

}

@media (max-width: 1024px) and (min-width: 768px) 
{
  
 

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) 
{
 
}
