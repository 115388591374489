@media screen and (max-width: 773px) {
  .redesign-home-about-section {
    height: auto;
    padding-right: 20px;
    padding-left: 20px;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0.05)
      ),
      url("../../public/assets/img/About/about-card.jpg");
  }
}
@media screen and (min-width: 774px) {
  .redesign-home-about-section {
    height: auto;
    padding-right: 20px;
    padding-left: 20px;
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0.05)
      ),
      url("../../public/assets/img/About/aboutus-new.jpg");
  }
}
@media screen and (max-width: 773px) {
  .redesign-home-div-right-about {
    margin-top: 171px;
  }
}
@media screen and (max-width: 572px) {
  .redesign-home-half-paragraph {
    width: 100%;
  }
}
@media screen and (min-width: 573px) {
  .redesign-home-half-paragraph {
    width: 65%;
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: Montserrat, sans-serif;
    color: #000;
    line-height: 24px;
    font-weight: 300;
    font-size:14px;
    padding-right: 20px;
  }
}
.redesign-home-about-section {
  background-size: auto, cover;
}

.redesign-home-empty-column {
  display: block;
  /* height: 600px; */
}
.redesign-home-right-text-about {
  display: flex;
  height: 600px;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
}
.redesign-home-div-right-about {
  width: auto;
  align-self: auto;
  flex: 0 auto;
  text-align: left;
}

p {
  font-size: 15px;
}
.redesign-home-btn-black {
  margin-top: 20px;
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-radius: 0 !important;
  background-color: #000 !important;
  transition: all 350ms ease !important;
  font-family: Montserrat, sans-serif !important;
  color: #fff !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  border-color: #fff !important;
  border-style: none !important;
  border-width: 1px !important;
  padding: 16px 40px !important;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  border: none;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
}

/* added by sk */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {

.redesign-home-half-paragraph {
  width: 100%;
}

}