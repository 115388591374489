.redesign-home-faq-section {
  position: static;
  background-color: #fff;
  transition: background-color 200ms ease;
  padding: 81px 20px;
}
.redesign-home-section-heading {
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  text-transform: none;
}
.redesign-home-full-container {
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  align-self: center;
}
.redesign-home-faq-columns {
  margin-top: 30px;
}
.redesign-home-question-div {
  margin-top: 20px;
  text-align: left;
}
.redesign-home-h6 {
  font-weight: bold !important;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  text-transform: uppercase;
}
.redesign-home-faq-p {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  line-height: 21px;
  font-weight:400;
}









@media only screen and (max-width: 767px) {


  .redesign-home-section-heading {
    font-size: 18px;
  }
  .redesign-home-faq-section {
    padding: 40px 20px;
}
.redesign-home-h6 {
  font-size: 13px;
}
.redesign-home-faq-p {
  font-size: 13px;
}













}


