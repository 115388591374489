#referral-hero-container {
  width: 100%;
  height: 55vh;
  max-height: 55vh;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../../public/assets/img/benifits/allbenifits-img.png"); */
  background-image: url("../../../public/assets/img/benifits/allbenifits-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 100px 75px 75px 100px;
}
#referral-hero-content {
  width: 100%;
  max-width: 1265px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 0 0;
  gap: 30px;
}
#referral-hero-heading-version-2 {
  font-size: 42px;
  letter-spacing: -2.31px;
}

.referral-hero-heading {
  color: #fff;
  font-family: Montserrat;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 111.688%;
  letter-spacing: -3.19px;
}
#referral-hero-subheading-version-2 {
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.32px;
}

.referral-hero-subheading {
  color: #fff;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 113.688%;
  letter-spacing: -0.32px;
}
#referral-hero-button {
  width: 279px;
  height: 54px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 3px;
  background: #208cc9;
  box-shadow: 0px 2px 7px 0px rgba(120, 137, 149, 0.25);
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}








@media only screen and (max-width: 767px) {
.referral-hero-heading {
  font-size: 34px;
}
#referral-hero-container {
  padding: 50px 15px 50px 15px;
  text-align: center;
}

#referral-hero-content {
  align-items: center;
}
.referral-hero-subheading {
  font-size: 24px;
}

}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {

  .referral-hero-heading {
    font-size: 34px;
  }
  #referral-hero-container {
    padding: 50px 15px 50px 15px;
    text-align: center;
  }
  
  #referral-hero-content {
    align-items: center;
  }
  .referral-hero-subheading {
    font-size: 24px;
  }
}


@media (max-width: 1024px) and (min-width: 768px) {
  .referral-hero-heading {
    font-size: 34px;
  }
  #referral-hero-container {
    padding: 50px 15px 50px 15px;
    text-align: center;
  }
  
  #referral-hero-content {
    align-items: center;
  }
  .referral-hero-subheading {
    font-size: 24px;
  }

}






@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  .referral-hero-heading {
    font-size: 58px;
  }
  #referral-hero-container {
    padding: 50px 15px 50px 15px;
    text-align: center;
  }
  
  #referral-hero-content {
    align-items: center;
  }
  .referral-hero-subheading {
    font-size: 32px;
  }

}
