
.forgotpw-container
{
	background: url("../../public/assets/img/Loginfooter/forgot-pw-bg.jpg") top left no-repeat fixed;
    background-size: cover;
	/* background-color: #01326a; */
}
.forgotpw-container-child
{
	padding:30px 60px;    
	background-color: #ffffff;
}
.forgotpw-container  a {
    color: #337ab7;
    text-decoration: none;
}
.forgotpw-container {
	text-align:center;
    padding-top: 60px;
    padding-bottom: 60px;
}
.forgotpw-container .forgot-text
{
    font-size: 20px;
    font-weight: 400;
	color: black;
	text-transform: capitalize;
	line-height: 28px;
}
.forgotpw-container .forgot-subtext
{
    font-size: 16px;
    font-weight: 400;
	line-height:33px;
	margin-bottom:10px;
}
.forgotpw-container  hr.border-blue
{
	border-color: #2a74e5 !important;
	opacity: 0.9;
}
.forgotpw-container  .forgot-form .form-group
{
	margin-bottom:15px;
}

.forgotpw-container  .forgot-form .form-group input.form-control
{
	font-size: 16px;
	border-radius: 4px;
	height: 46px;
    padding: 10px 16px;
	color:#333;
	border: 1px solid #ccc;
}
.forgotpw-container  .forgot-form .form-group input.form-control:focus 
{
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}


.forgotpw-container  .forgot-form .btn-signin 
{
	font-size: 18px;
	font-weight:600;
	text-transform: capitalize;
	color:#fff;
	border-radius: 0px;
	padding:6px 50px;
	margin-top:15px;
	width:auto;
}
.forgotpw-container  .forgot-form .btn-signin a
{
	color:#fff;
}
.forgotpw-container  .trouble-text
{
	font-size:14px;
}


@media only screen and (max-width: 767px) {

	
	.forgotpw-container {
		padding-top: 40px;
		padding-bottom: 80px;
	}

	.forgotpw-container .forgot-text {
		font-size: 16px;
	}
	.forgotpw-container .forgot-subtext {
		font-size: 12px;
        line-height: 28px;
	}
	.forgotpw-container  .trouble-text
	{
		font-size:12px;
	}
	.forgotpw-container-child {
		padding: 15px;
	}




}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {

	.forgotpw-container {
		padding-top: 40px;
		padding-bottom: 80px;
	}

	.forgotpw-container .forgot-text {
		font-size: 16px;
	}
	.forgotpw-container .forgot-subtext {
		font-size: 12px;
        line-height: 28px;
	}
	.forgotpw-container  .trouble-text
	{
		font-size:12px;
	}
	
	
}


@media (max-width: 1024px) and (min-width: 768px) {

	.forgotpw-container {
		padding-top: 180px;
		padding-bottom: 150px;
	}
	
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.forgotpw-container {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	
}