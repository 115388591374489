.bottom-to-top {
    background-color: #3b3b3b;
	padding:20px;
	cursor:pointer;
}

.bottom-to-top img{
   width:10px;
}
.bottom-to-top h5{
	font-size:14px;
	font-weight:500;
	font-style: normal;
	color:#ffffff;
	margin-bottom:0;
 }
@media only screen and (max-width: 767px) {
	
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {

	
}


@media (max-width: 1024px) and (min-width: 768px) {

	
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

	
	
}